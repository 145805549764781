export const SavedIcon = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.08333 8.74998L10.3066 11.1674C10.3689 11.2141 10.4565 11.2055 10.5084 11.1475L17.5 3.33331"
        stroke="#1D2943"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M17.5868 8.50134C17.9292 10.2348 17.6677 12.0333 16.8457 13.5975C16.0237 15.1616 14.6909 16.3971 13.069 17.0984C11.4471 17.7996 9.63395 17.9242 7.93135 17.4515C6.22876 16.9788 4.73938 15.9373 3.7111 14.5004C2.68281 13.0634 2.17763 11.3176 2.27965 9.55356C2.38166 7.78952 3.0847 6.1136 4.27177 4.80474C5.45885 3.49588 7.05835 2.63301 8.80408 2.35973C10.5498 2.08646 12.3365 2.41926 13.8667 3.30276"
        stroke="#1D2943"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
};
