// @ts-nocheck
import { PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from "react";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Grid, Typography, Box, makeStyles, TextField } from "@material-ui/core";
import { SnackBarContext } from "components/snack-bar";
import { Icon } from "components/icons";
import { VisiblePostCodeType } from "store/types";

import { styles } from "./style";
import {
  CityType,
  GetDetailsCallbackType,
  GetPlacePredictionsType,
  GetPlacePredictionRequestType,
  GetPlacePredictionCallbackType,
  OptionType,
  PartsType,
  LocationLatLngType,
} from "./type.js";

const useStyles = makeStyles(() => styles);

type PlaceAutocompleteProps = PropsWithChildren<{
  city?: CityType;
  countryCode?: string | null;
  disabled?: boolean;
  key?: string;
  markerInputValue?: OptionType;
  restrictedIds?: string[];
  placeholder?: string;
  postCodeSearch?: boolean;
  handleRemoveAddress?: () => void;
  onLoad?: React.ReactEventHandler<HTMLDivElement>;
  onPlaceChanged?: (newMarker: LocationLatLngType) => void;
  getFilteredPostCodes?: (key: string) => VisiblePostCodeType[];
  noOptionText?: string;
}>;

export const PlaceAutocomplete = ({
  city = undefined,
  countryCode = null,
  disabled = false,
  key = undefined,
  markerInputValue = undefined,
  restrictedIds = undefined,
  placeholder,
  postCodeSearch = false,
  handleRemoveAddress = () => {
    console.log("We dont have function handleRemoveAddress");
  },
  onLoad = undefined,
  onPlaceChanged = () => {},
  getFilteredPostCodes = () => [],
  noOptionText = "Start typing to see suggestions",
}: PlaceAutocompleteProps) => {
  const classes = useStyles();
  const [value, setValue] = useState<OptionType | null>(markerInputValue ? markerInputValue : null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<OptionType[]>([]);

  const runSnackBar = useContext(SnackBarContext);

  const autocompleteService = useRef<google.maps.places.AutocompleteService | null>(null);
  const placesService = useRef<google.maps.places.PlacesService | null>(null);

  const resetAllValues = () => {
    setValue(null);
    setInputValue("");
    setOptions([]);
  };

  const fetchPlacePredictions: GetPlacePredictionsType = useMemo(
    () =>
      throttle((request: GetPlacePredictionRequestType, callback: GetPlacePredictionCallbackType) => {
        autocompleteService.current?.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  const getPlaceDetails: GetDetailsCallbackType = (placeDetails) => {
    onPlaceChanged({
      formattedAddress: placeDetails?.formatted_address || "",
      geometry: {
        location: {
          lat: () => placeDetails?.geometry?.location?.lat() || 0,
          lng: () => placeDetails?.geometry?.location?.lng() || 0,
        },
      },
    });
  };

  const handleAutocompleteChange = (newValue: OptionType | null) => {
    if (!placesService.current) {
      return undefined;
    }

    if (newValue) {
      if (!newValue.isPostcode) {
        const request = {
          placeId: newValue.place_id,
        };
        placesService.current.getDetails(request, getPlaceDetails);
      } else if (postCodeSearch) {
        onPlaceChanged({
          formattedAddress: newValue?.description || "",
          geometry: {
            location: {
              lat: () => newValue.postCode?.center.lat || 0,
              lng: () => newValue.postCode?.center.lng || 0,
            },
          },
          isPostcode: true,
          postCode: newValue.postCode,
        });
      }

      setValue(newValue);
    } else {
      handleRemoveAddress();
    }
    setTimeout(() => {
      resetAllValues();
    }, 300);
  };

  const handleInputChange = (newInputValue: string) => {
    if (!newInputValue) {
      return resetAllValues();
    }

    if (!/^[a-zA-Z0-9àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ$@$!%*?&#^-_-,'. +()]+$/.test(newInputValue)) {
      runSnackBar({
        type: "error",
        msg: `Only Latin characters are allowed`,
        vertical: "top",
        horizontal: "right",
      });
      return resetAllValues();
    }

    setInputValue(newInputValue);

    if (!autocompleteService.current || newInputValue === "") {
      return undefined;
    }

    const request: GetPlacePredictionRequestType = {
      input: city ? `${city.nameWithState ? city.nameWithState : city.name}, ${newInputValue}` : newInputValue,
      language: "en",
      componentRestrictions: { country: countryCode },
    };

    fetchPlacePredictions(request, (results) => {
      let newOptions: OptionType[] = [];

      if (value) {
        newOptions = [value];
      }

      if (results) {
        newOptions = [...newOptions, ...results];
      }

      if (restrictedIds?.length) {
        newOptions = newOptions.filter((newOption) => !restrictedIds.includes(newOption.place_id));
      }

      const postCodes = postCodeSearch
        ? getFilteredPostCodes(newInputValue).map((postCode) => ({
            description: postCode.name,
            matched_substrings: [],
            place_id: "",
            isPostcode: true,
            postCode: postCode,
          }))
        : [];

      setOptions([...postCodes, ...newOptions]);
    });
  };

  useEffect(() => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!placesService.current && window.google) {
      const map = document.createElement("div");
      placesService.current = new window.google.maps.places.PlacesService(map);
    }
  }, []);

  const noOptionsTextRender = (customText: string) => {
    if (options.length === 0) {
      if (!inputValue) {
        return customText;
      } else {
        return "No options found";
      }
    }
  };

  return (
    <Autocomplete
      id={key}
      onLoad={onLoad}
      className={classes.placesAutocomplete}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      blurOnSelect
      noOptionsText={noOptionsTextRender(noOptionText)}
      clearOnBlur
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => handleAutocompleteChange(newValue)}
      onInputChange={(event, newInputValue) => handleInputChange(newInputValue)}
      disabled={disabled}
      renderInput={(params) => (
        <Box className={classes.textFieldV2}>
          <Icon type="Search" size="m" />
          <TextField
            {...params}
            value={inputValue}
            // className={classes.textField}
            placeholder={placeholder}
            variant="outlined"
            size="small"
            fullWidth
          />
        </Box>
      )}
      renderOption={(option) => {
        if (!option.isPostcode) {
          const matches = option.structured_formatting?.main_text_matched_substrings || [];
          const parts: PartsType[] = parse(
            option.structured_formatting?.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );

          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part: any, index: number) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting?.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              <Typography style={{ fontWeight: 700 }}>{option.description}</Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};
