import { useCallback } from "react";
import { toFixed2 } from "utils/formators";
import { getDMPrintingCostByFlyer } from "../../shared/campaign";
import { StoreType, CostsCalculationDataType } from "../../store/types";
import { calculateTaxAndTotalCost } from "./utils";
import { getDMFlyersAmount } from "../../dm-campaign-data-hoc/utils";
import { useStore } from "../../store";
import { CAMPAIGN_INTERNAL_STATUSES } from "../../pages/constants";

export const useEDDMCostsCalculation = (): {
  recalculateEDDMCampaignCost: () => void;
} => {
  const {
    campaign: { flyerType, internalStatus },
    country,
    costsCalculationData,
    distributionLocations = [],
    updateCostsCalculationData,
  }: StoreType = useStore();

  const { printingCosts, detailedCost }: CostsCalculationDataType = costsCalculationData;
  const { amountOff = 0, percentOff } = detailedCost || {};
  const countryTaxRate = country?.countryDefaultSettings?.countryTaxRate || 0;

  const recalculateEDDMCampaignCost = useCallback(() => {
    // if (missionsCount === totalMissionsCount || internalStatus !== CAMPAIGN_INTERNAL_STATUSES.DRAFT) {
    if (internalStatus !== CAMPAIGN_INTERNAL_STATUSES.DRAFT) {
      return; // Early return if missions count hasn't changed
    }

    const flyersAmount =
      distributionLocations && distributionLocations.length ? getDMFlyersAmount(distributionLocations) : 0;

    const costPerFlyer = getDMPrintingCostByFlyer({
      printingCosts,
      flyerType,
      flyersAmount,
    });

    const subtotal = flyersAmount * costPerFlyer;
    const fixed2Subtotal = toFixed2(subtotal);

    const { tax, totalCost } = calculateTaxAndTotalCost({ countryTaxRate, subtotal: fixed2Subtotal, amountOff });

    updateCostsCalculationData({
      ...costsCalculationData,
      costPerFlyer,
      flyersAmount,
      detailedCost: {
        subtotal: fixed2Subtotal,
        tax: toFixed2(tax),
        totalCost: toFixed2(totalCost),
        amountOff,
        percentOff,
      },
      settings: { countryTaxRate },
    });
  }, [flyerType, distributionLocations, internalStatus, amountOff, percentOff]);

  return { recalculateEDDMCampaignCost };
};
