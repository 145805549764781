import { SavingIcon } from "components/icons/components/SavingIcon";
import { SavedIcon } from "components/icons/components/SavedIcon";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    width: "87px",
  },
  label: {
    fontFamily: "'Proxima Nova', 'sans-serif'",
    marginLeft: "8px",
  },
}));

export const Saving = ({ saving }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {saving ? (
        <>
          <SavingIcon /> <span className={classes.label}>Saving...</span>
        </>
      ) : (
        <>
          <SavedIcon /> <span className={classes.label}>Saved</span>
        </>
      )}
    </div>
  );
};
