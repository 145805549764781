/* eslint-disable react/no-unknown-property */
import { withStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Header from "components/header";
import BounceLoader from "components/loaders/bounce-loader";
import cn from "classnames";
import ROUTES from "config/routing";
import { NAVIGATION, EDDM_NAVIGATION } from "pages/constants";
import Button from "pages/flyer/flyerTypePage/components/Button";
import ConfirmationPopUp from "pages/flyer/flyerTypePage/components/ConfirmationPopUp";
import { useGetDataForPage } from "pages/flyer/flyerTypePage/useGetDataForPage";
import { useGetFlyerInfo } from "pages/flyer/flyerTypePage/useGetFlyerInfo";
import React, { memo } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";
import style from "./style";
import { useStore } from "../../../store";
import { StoreType } from "../../../store/types";

const SelectFlyerTypePage: React.FC<{ classes: any }> = ({ classes }) => {
  const flyerInfo = useGetFlyerInfo();
  const {
    campaign: { id: campaignId, campaignName, isDM },
    client: { id: clientId },
    user: { id: userId, isVerified },
  }: StoreType = useStore();

  const history = useHistory();

  const {
    firstLevelHeaderComponent,
    secondLevelHeaderComponent,
    buttons,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
  } = useGetDataForPage(flyerInfo.pageState);

  const headerActions = {
    BACK: {
      action: async () => {
        history.push(generatePath(ROUTES.CAMPAIGN_AUDIENCE, { campaignId, clientId }));
      },
    },
  };

  return (
    <div className={cn(classes.wrapper)}>
      <Helmet>
        <title>Flyer</title>
      </Helmet>
      <Header tabs={isDM ? EDDM_NAVIGATION : NAVIGATION} activeTabIndex={2} headerActions={headerActions} isDM={isDM} />
      {flyerInfo.isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <BounceLoader />
        </Box>
      ) : (
        <div className={cn(classes.content, "mob-flyer-wrapper", { [classes.contentNotVerified]: !isVerified })}>
          <div className={cn(classes.title, "mob-header-title")} cypress_id="campaignNameTitle">
            {campaignName}
          </div>
          <div className={cn(classes.firstLevelHeader, "mob-header-title")} cypress_id="firstLevelHeader">
            {firstLevelHeaderComponent}
          </div>
          <div className={cn(classes.secondLevelHeader, "mob-header-title")} cypress_id="secondLevelHeader">
            {secondLevelHeaderComponent}
          </div>
          <div className={cn(classes.buttons, "mob-flyer-select-design-wrapper")}>
            {buttons.map((button) => {
              return (
                <Button
                  key={`select_flyer_type_page_${button.title}_button`}
                  title={button.title}
                  description={button.description}
                  image={<button.icon />}
                  action={() => button.action(clientId, userId)}
                  cypressId={`${button.title.toLowerCase().replace(/\s+/g, "-")}-btn`}
                />
              );
            })}
          </div>
          <ConfirmationPopUp isOpen={isConfirmationModalOpen} onClose={() => setIsConfirmationModalOpen(false)} />
        </div>
      )}
    </div>
  );
};

export default memo(withStyles(style)(SelectFlyerTypePage));
