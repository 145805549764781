import { Box, Typography, makeStyles } from "@material-ui/core";
import { Icon } from "components/icons";

import { style } from "./style";

const useStyles = makeStyles(() => style);

export const LoadingScreen = ({ loadingPercentage }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.background} />
      <Box className={classes.wrapper}>
        <Typography className={classes.loadingWrapper}>
          <Icon type="LoadingBigIcon" size="xxxl" />
        </Typography>
        <Typography className={classes.title}>Loading distribution areas</Typography>
        <Typography className={classes.description}>This process may take a moment.</Typography>
        {loadingPercentage && <Typography className={classes.description}>{`${loadingPercentage} %` || ""}</Typography>}
      </Box>
    </>
  );
};

export default LoadingScreen;
