import { MAP_TYPES } from "../../pages/constants";

const defaultOptions = {
  strokeColor: "#ee4360",
  strokeOpacity: 1,
  strokeWeight: 1,
  fillColor: "#FF0000",
  fillOpacity: 0,
  clickable: true,
  draggable: true,
  editable: true,
  visible: true,
  zIndex: 1,
};

const BROAD_AREAS_MAP_OPTIONS = {
  drawingControlOptions: {
    drawingModes: [MAP_TYPES.CIRCLE, MAP_TYPES.POLYGON],
  },
  drawingControl: false,
  polygonOptions: defaultOptions,
  circleOptions: defaultOptions,
};

const PRECISE_LOCATIONS_MAP_OPTIONS = {
  drawingControlOptions: {
    drawingModes: [MAP_TYPES.MARKER],
  },
  drawingControl: false,
  markerOptions: defaultOptions,
};

const AREA_TYPE = {
  DISTRIBUTION: "distribution",
  EXCLUSION: "exclusion",
};

const BUILDER_MAP_MODE = {
  EDIT: "BUILDER_MAP_MODE.EDIT",
  TOGGLE: "BUILDER_MAP_MODE.TOGGLE",
};

const BUILDER_MAP_COLOR_INACTIVE = "#000000";
const BUILDER_MAP_COLOR_POSTCODE = "#EE4360";

const OUTER_CORDS = [
  { lat: -85, lng: -180 }, // north west
  { lat: -85, lng: 90 }, // north east
  { lat: -85, lng: 180 }, // south east
  { lat: -85, lng: 0 }, // south west
  { lat: -85, lng: -180 },
];

const TURF_REWIND_OUTER_COORDS = [
  [-180, -85],
  [90, -85],
  [180, -85],
  [0, -85],
  [-180, -85],
];

const MAP_BUTTONS = {
  SELECT_LOCATION: "SELECT_LOCATION",
  CREATE_LOCATION: "CREATE_LOCATION",
  SELECT_AREA: "SELECT_AREA",
  CIRCLE_SELECT: "CIRCLE_SELECT",
  POLYGON_SELECT: "POLYGON_SELECT",
};

const MAP_EXTRA_STYLES = [
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

export {
  BROAD_AREAS_MAP_OPTIONS,
  PRECISE_LOCATIONS_MAP_OPTIONS,
  AREA_TYPE,
  BUILDER_MAP_MODE,
  BUILDER_MAP_COLOR_INACTIVE,
  BUILDER_MAP_COLOR_POSTCODE,
  OUTER_CORDS,
  TURF_REWIND_OUTER_COORDS,
  MAP_BUTTONS,
  MAP_EXTRA_STYLES,
};
