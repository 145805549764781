export const styles = {
  addressesContainer: {
    position: "absolute",
    left: "24px",
    top: "16px",
    borderRadius: "8px",
    backgroundColor: "#F9F9FF",
    width: "322px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    maxHeight: "96%",
    overflow: "scroll",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    fontWeight: 600,
    fontFamily: "'Proxima Nova', 'sans-serif'",
    fontSize: "18px",
    marginBottom: "5px",
  },
};
