import React, { useState, useEffect } from "react";
import cn from "classnames";
import { formatNumber } from "utils/formators";
import { makeStyles } from "@material-ui/core";
import { DeleteIcon } from "static/media/delete-icon";
import { useStore } from "store";
import { styles } from "./styles";
import { AddressIconSelected, AddressIconUnselected } from "./icons";

const useStyles = makeStyles(() => styles);

export const AddressCard = ({ address = {}, onClick, handleDeleteLocation }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(true);
  const { isFocused, name, selectedRoutes } = address;
  const classes = useStyles();
  const {
    campaign: { isSubmitted },
    costsCalculationData: { costPerFlyer },
  } = useStore();

  useEffect(() => {
    if (isFocused) {
      setDeleteDisabled(false);
    }
  }, []);

  const totalAddresses = selectedRoutes.reduce((acc, curr) => {
    return acc + curr.metadata.APT_COUNT + curr.metadata.HOME_COUNT;
  }, 0);

  return (
    <div
      className={cn(classes.addressCardContainer, {
        [classes.focused]: isFocused,
        [classes.deleting]: isDeleting,
      })}
      onClick={onClick}
      onMouseEnter={() => {
        setDeleteDisabled(false);
      }}
      onMouseLeave={() => {
        setDeleteDisabled(true);
      }}
    >
      <div className={cn(classes.deletingOverlap, { [classes.deletingAnimation]: isDeleted })} />
      <div className={classes.addressHeader}>
        <div className={cn(classes.addressTitle, { [classes.shortTitle]: isDeleting })}>
          {isDeleting ? <b>Delete</b> : isFocused ? <AddressIconSelected /> : <AddressIconUnselected />}
          <span className={classes.name}>{name}</span> {isDeleting ? "?" : ""}
        </div>
        {isDeleting ? (
          <div className={classes.deleteConfirmationButtonsWrapper}>
            <div
              className={classes.noButton}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsDeleting(false);
              }}
            >
              No
            </div>
            <div
              className={classes.yesButton}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsDeleted(true);
                setTimeout(() => {
                  handleDeleteLocation(address);
                }, 200);
              }}
            >
              Yes
            </div>
          </div>
        ) : (
          <DeleteIcon
            disabled={deleteDisabled || isSubmitted}
            width={"16"}
            height={"16"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (isSubmitted) return;
              setIsDeleting(true);
            }}
          />
        )}
      </div>
      <div className={classes.addressStatistics}>
        <div>
          <span className={classes.statisticLabel}>Routes: </span> <span>{selectedRoutes?.length || 0}</span>
        </div>
        <div>
          <span className={classes.statisticLabel}>Addresses: </span>
          <span>{formatNumber(totalAddresses)}</span>
        </div>
        <div>
          <span className={classes.statisticLabel}>Costs: </span>{" "}
          <span>${formatNumber(costPerFlyer * totalAddresses)}</span>
        </div>
      </div>
    </div>
  );
};
